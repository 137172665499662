.history {
  margin-top: 3%;
}

.history-container {
  position: relative;
  margin-top: 1%;
  display: flex;
  flex-direction: column;
}

.table-search {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2%;
}

.table-search > input {
  padding: 5px;
  padding-right: 15px;
}

.table-search .table-search-icon {
  position: absolute;
  right: 10px;
}

.history-table {
  font-size: 12px;
  height: 85vh;
  overflow: auto;
}

.history-table > table {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  white-space: nowrap;
}

.history-table th {
  background-color: rgb(54, 54, 54);
  color: rgb(228, 228, 228);
  padding: 7px 0px;
  border: rgb(230, 230, 230) 2px solid;
  position: sticky;
  top: -1px;
  overflow: hidden;
}

.history-table td {
  border: rgb(230, 230, 230) 2px solid;
}

.history-table th:first-child {
  position: sticky;
  left: -1px;
  z-index: 2;
}

.history-table td:first-child {
  position: sticky;
  left: -1px;
  color: white;
  background-color: rgb(54, 54, 54);
  border: 1px solid rgb(230, 230, 230);
  max-width: 120px;
  overflow: hidden;
}

.delivered {
  background-color: #2eb5c5;
}

.comment {
  background-color: rgb(247, 201, 115);
}

.history-table .table-product {
  min-width: 40px;
  max-width: 50px;
}

tr {
  cursor: pointer;
}

tr:hover {
  background-color: #9ff5ff;
}

@media screen and (max-width: 480px) {
  .history h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .history-container {
    margin-top: 15px;
  }

  .table-search {
    padding: 5px;
  }

  .table-search > input {
    padding: 7px;
  }

  .history-container .table-product {
    padding: 0px 5px;
  }

  .history-table {
    font-size: 12px;
    height: 60vh;
    overflow: auto;
  }
}
