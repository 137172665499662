.setting {
  margin-top: 4%;
  height: 90vh;
}

.setting-container {
  margin: 10px;
  padding: 10px;
}

.setting-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs > button {
  border: none;
  padding: 15px;
  color: black;
  font-weight: bold;
  font-size: 15px;
}

.setting-search {
  position: relative;
}

.setting-search input {
  padding: 5px;
  text-align: left;
  width: 200px;
}

.setting-search-icon {
  position: absolute;
  right: 10px;
  top: 6px;
}

.search-result {
  width: 89%;
  position: absolute;
  top: 1;
  height: 15vh;
  z-index: 10;
  border: 1px solid black;
  overflow: auto;
  padding: 11px;
  background-color: aliceblue;
  text-align: left;
}

.search-result > p {
  font-size: 15px;
  font-weight: bold;
  padding: 5px;
}

.search-result > p:hover {
  cursor: pointer;
  background-color: #2079d8;
}

.hidden {
  display: none;
}

.setting-container-body {
  padding: 100px 20px;
  background-color: rgb(230, 247, 253);
  position: relative;
}

.setting-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.setting-form span {
  color: red;
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.setting-container-body label {
  display: inline-block;
  width: 120px;
  margin-right: 5px;
  font-weight: bold;
  text-align: right;
  margin-right: 10px;
}

.setting-container-body input {
  padding: 4px;
  width: 180px;
}

.setting-form-button {
  margin: 50px;
}

.setting-form-button button {
  width: 80px;
  padding: 5px;
  border-radius: 10px;
  margin: 5px;
  color: white;
}

.setting-form-submit {
  background-color: #2079d8;
}

.setting-form-cancel {
  background-color: rgb(235, 176, 68);
}

.delete-form {
  position: absolute;
  bottom: 7rem;
  left: 50%;
  transform: translateX(-50%);
}

.setting-form-delete {
  width: 80px;
  padding: 5px;
  border-radius: 10px;
  margin: 5px;
  color: white;
  background-color: #e72b34;
}

.setting-form-submit:hover {
  background-color: #104a88;
}

.setting-form-cancel:hover {
  background-color: rgb(206, 137, 9);
}

.setting-form-delete:hover {
  background-color: #b40b14;
}

@media screen and (max-width: 480px) {
  .setting h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .tabs > button {
    font-size: 12px;
    padding: 10px 7px;
    width: 85px;
  }

  .setting-search input {
    padding: 5px;
    width: 120px;
  }

  .search-result {
    width: 110px;
  }

  .search-result > p {
    font-size: 10px;
  }

  .setting-container-body {
    padding: 30px;
  }

  .setting-container-body label {
    width: 100px;
    font-size: 11px;
  }

  .setting-container-body input {
    width: 135px;
  }

  .setting-form span {
    font-size: 12px;
  }

  .setting-form-button {
    margin: 20px;
  }

  .delete-form {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1024px) {
  .setting-container-body label {
    width: 150px;
    font-size: 15px;
  }

  .setting-container-body input {
    width: 195px;
  }

  .setting-form-button button {
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
  }
}
