.form {
  margin-top: 1%;
  font-size: 15px;
}

.hidden {
  display: hidden;
  margin: auto;
  transform: translateY(-50%);
  font-size: 20px;
  padding: 15px;
  width: 60%;
  color: white;
  text-align: center;
}

.notification {
  display: block;
  margin: auto;
  transform: translateY(-50%);
  font-size: 20px;
  padding: 15px;
  width: 60%;
  color: white;
  text-align: center;
  margin-top: 2%;
}

.order-form {
  border-radius: 10px;
  margin: 0px 5px;
  margin-top: 2%;
  position: relative;
  height: 89vh;
}

.order-form-container {
  background-color: rgb(204, 204, 204);
  padding: 25px;
}

.order-form-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.sales > label,
.customer > div > label,
.date > label {
  font-weight: bold;
  margin-right: 15px;
}

.sales > input,
.customer > div > input,
.date > input {
  width: 180px;
  padding: 7px;
}

.customer {
  position: relative;
}

.customers-list,
.products-list-container {
  height: 300px;
  width: 100%;
  border: 1px solid black;
  overflow: auto;
  background-color: white;
  font-size: 15px;
  position: absolute;
  top: 1;
  left: 0;
  z-index: 10;
}

.customers-list > p {
  text-align: left;
  padding: 5px;
}

.customers-list > p:hover {
  cursor: pointer;
  background-color: #2079d8;
}

.order-form-body {
  margin-top: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3%;
}

.order-form-body .submit {
  padding: 8px;
  background-color: #2079d8;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.order-form-body .reset-form {
  background-color: #e72b34;
  margin-left: 15px;
  padding: 8px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.order-form-body .submit:hover,
.products-list-add:hover {
  background-color: #104a88;
}

.order-form-body .reset-form:hover,
.products-list-cancel:hover {
  background-color: #b40b14;
}

.search-product {
  border-radius: 15px;
  background-color: rgb(43, 177, 39);
  padding: 8px 25px;
  max-width: 600px;
}

.search-product > label {
  font-weight: bold;
  margin-right: 25px;
  color: rgb(255, 255, 255);
}

.search-product > input {
  padding: 5px;
  min-width: 300px;
}

.products-list-container {
  overflow: hidden;
  padding: 5px;
}

.products-list {
  height: 250px;
  width: 100%;
  overflow: auto;
  border-bottom: 1px solid black;
}

.products-list > label {
  display: block;
  margin: 10px;
  letter-spacing: 1px;
  text-align: left;
}

.products-list-add {
  background-color: #2079d8;
  padding: 8px;
  min-width: 90px;
  margin: 5px;
  border-radius: 10px;
  color: white;
  margin-top: 15px;
}

.products-list-cancel {
  background-color: #e72b34;
  padding: 8px;
  min-width: 90px;
  margin: 5px;
  border-radius: 10px;
  color: white;
  margin-top: 15px;
}

.table-container {
  max-height: 50vh;
  overflow-y: scroll;
  padding-bottom: 5px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th {
  position: sticky;
  top: 0;
  background-color: rgb(245, 206, 133);
}

table {
  width: 80%;
  float: left;
}

table > tbody > tr > td > button {
  border: none;
  font-size: 20px;
  font-weight: bold;
  padding: 2px 7px;
}

table > tbody > tr > td > button:hover {
  font-size: 25px;
  cursor: pointer;
}

th,
td {
  padding: 10px;
  height: 4vh;
}

td > input {
  padding: 5px;
  width: 50px;
  text-align: center;
}

.order-form-footer {
  position: absolute;
  bottom: 5px;
  left: 0;
  background-color: rgb(204, 204, 204);
  width: 100%;
  padding: 20px 0px;
}

.order-form-footer .message {
  padding: 5px;
  float: left;
  resize: none;
  margin-left: 20px;
}

.order-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 480px) {
  .form {
    font-size: 12px;
    margin-bottom: 100px;
    height: 100vh;
  }

  .hidden {
    display: hidden;
    margin: auto;
    font-size: 5px;
    padding: 15px;
    width: 60%;
    color: white;
    text-align: center;
  }

  .notification {
    display: block;
    margin: auto;
    font-size: 15px;
    padding: 10px;
    width: 60%;
    color: white;
    text-align: center;
    margin-top: 10%;
  }

  .form h2 {
    font-size: 15px;
    margin-top: 10px;
  }

  .order-form-container {
    background-color: rgb(204, 204, 204);
    padding: 10px 5px;
    max-width: 100%;
    height: 29vh;
    margin-top: 8%;
  }

  .order-form-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .sales > label,
  .customer > div > label,
  .date > label {
    display: inline-block;
    width: 70px;
    text-align: left;
  }

  .sales > input,
  .customer > div > input,
  .date > input {
    width: 150px;
    padding: 5px;
  }

  .customers-list {
    height: 200px;
    width: 67%;
    border: 1px solid black;
    overflow: auto;
    background-color: white;
    font-size: 9px;
    position: absolute;
    top: 1;
    left: 83px;
    z-index: 10;
  }

  .customers-list > p {
    text-align: left;
    padding: 5px;
  }

  .order-form-body {
    margin-top: 15px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;
  }

  .order-form-body > div > button {
    border-radius: 10px;
    color: white;
    width: 70px;
    font-weight: bold;
    font-size: 10px;
  }

  .search-product {
    border-radius: 15px;
    background-color: rgb(43, 177, 39);
    padding: 8px;
  }

  .products-list-container {
    height: 200px;
    position: absolute;
    top: 1;
    left: 0;
    z-index: 10;
  }

  .products-list {
    height: 150px;
    width: 100%;
    overflow: auto;
    border-bottom: 1px solid black;
    font-size: 10px;
  }

  .products-list-container > button {
    padding: 5px;
    min-width: 50px;
    margin: 5px;
    border-radius: 10px;
    color: white;
    margin-top: 15px;
  }

  .table-container {
    height: 35vh;
    overflow: auto;
    padding-bottom: 5px;
    margin-bottom: 50px;
    font-size: 10px;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th {
    position: sticky;
    top: 0;
    background-color: rgb(245, 206, 133);
  }

  table {
    width: 100%;
    float: left;
  }

  table > tbody > tr > td > button {
    border: none;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 7px;
  }

  table > tbody > tr > td > button:hover {
    font-size: 12px;
    cursor: pointer;
  }

  th,
  td {
    padding: 3px;
    height: 3vh;
  }

  td > input {
    padding: 2px;
    width: 40px;
    text-align: center;
  }

  .order-form-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgb(204, 204, 204);
    width: 100%;
    padding: 4px 0px;
  }

  .order-form-footer .message {
    height: 30px;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .notification {
    font-size: 14px;
    margin-top: 30px;
  }

  .sales > label,
  .customer > div > label,
  .date > label {
    display: block;
    width: 90px;
    text-align: left;
    font-size: 14px;
    margin-left: 5px;
  }

  .sales > input,
  .customer > div > input,
  .date > input {
    width: 150px;
    padding: 7px;
  }
}
