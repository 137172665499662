:root {
  --show: flex;
}

.layout {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.mobile {
  display: none;
}

.navbar {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 200px;
  background-color: #2f4050;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
  height: 100vh;
}

.outlet {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.signout {
  position: absolute;
  right: 20px;
  top: 10px;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 3px;
}

/* .signout .signout-btn {
  margin: 1px;
  vertical-align: top;
}

.signout p {
  margin: 1px;
  vertical-align: top;
  display: inline-block;
} */

.signout:hover {
  border: #2f4050 1px solid;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .layout {
    display: block;
    position: relative;
  }

  .outlet {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    height: 90vh;
  }

  .navbar {
    width: 50%;
    background-color: rgb(0, 0, 0);
    display: var(--show);
    flex-direction: column;
    z-index: 10;
    position: relative;
  }

  .mobile {
    display: flex;
    position: absolute;
    left: 15px;
    top: 15px;
    padding: 5px;
    font-size: 25px;
    background-color: black;
    color: white;
    z-index: 20;
  }

  .signout {
    position: absolute;
    right: 10px;
    top: 16px;
    font-size: 22px;
  }

  .signout p {
    display: none;
  }

  .signout:hover {
    border: none;
  }
}
