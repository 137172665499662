.error {
  background-color: bisque;
  padding: 10px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  border-radius: 10px;
  color: rgb(248, 46, 46);
}

.login-form {
  height: 50vh;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  padding: 25px;
  padding-bottom: 0;
  color: white;
}

.login-form h2 {
  margin-top: 60px;
  display: block;
  margin: 20px;
}

.username,
.password {
  position: relative;
  max-width: 310px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}

.login-form span {
  position: absolute;
  left: 50px;
  top: 8px;
  color: black;
}

.login-form div input {
  max-width: 220px;
  height: 30px;
  border-radius: 10px;
  padding: 0px 35px;
  font-size: 15px;
}

label > input {
  margin-right: 2px;
}

.login-form button {
  padding: 11px 50px;
  margin-top: 20px;
  border-radius: 10px;
}

.login-form button:hover {
  background-color: rgb(177, 177, 177);
  color: white;
}

@media screen and (max-width: 480px) {
  .App {
    overflow: hidden;
  }

  .App .title {
    font-size: 23px;
    vertical-align: baseline;
    padding: 10px;
  }

  .App .login {
    font-size: 20px;
  }

  .App .title > img {
    float: left;
  }
}
