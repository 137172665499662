.editing-order-container {
  background-color: rgb(204, 204, 204);
  padding: 25px;
}

.delivery-date > label {
  font-weight: bold;
  margin-right: 15px;
}

.delivery-date > input {
  width: 180px;
  height: 4px;
  padding: 13px 8px;
}

@media screen and (max-width: 480px) {
  .editing-order-container {
    background-color: rgb(204, 204, 204);
    padding: 10px 5px;
    max-width: 100%;
    height: 35vh;
    margin-top: 7%;
  }

  .delivery-date > label {
    display: inline-block;
    width: 70px;
    text-align: left;
    margin-top: 3px;
  }

  .delivery-date > input {
    appearance: none;
    width: 144px;
    height: 3px;
    padding: 10px 8px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .delivery-date > label {
    display: block;
    width: 90px;
    text-align: left;
    font-size: 14px;
    margin-left: 5px;
  }

  .delivery-date > input {
    appearance: none;
    width: 150px;
    height: 10px;
    padding: 10px 8px;
    background-color: white;
    border: 1px solid rgb(155, 155, 155);
  }
}
