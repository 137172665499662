.navbar .logo {
    width: 130px;
    height: 80px;
    padding-top: 20px;
}

ul {
  list-style: none;
  margin-top: 50px;
  margin-bottom: 100px;
  color: white;
  width: 100%;
}

a {
    text-decoration: none;
}

.active .link {
  background-color: #1f2831;
}

.link {
  color: white;
  font-size:15px;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.link:hover {
    cursor: pointer;
    background-color: #293846;
}

.link-icon, .contact-icon {
  margin-left: 15%;
}

/* .link-icon, .contact-icon {
  flex: 30%;
  display: grid;
  place-items: center;
  text-align: right;
}

.link-title, .contact-title {
  flex: 70%;
  text-align: left;
} */

@media screen and (max-width: 480px) {
  .logo {
    display: none;
  }

  ul {
    margin-top: 50%;
  }

  .link .link-title, .contact-title, .link-icon, .contact-icon {
    font-size: 15px;
  }

  .active .link {
    background-color: #1a2229;
  }

  .link-icon, .contact-icon {
    margin-left: 15%;
  }
}

