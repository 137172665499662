.App {
  text-align: center;
  background-image: url("./assets/background.jpg");
  background-size: cover;
  height: 100vh;
}

.title {
  font-weight: 800;
  font-size: 40px;
  display: block;
  color: white;
}

.title img {
  width: 90px;
  height: 55px;
  padding-top: 0px;
  vertical-align: middle;
  margin-right: 15px;
}