#error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 100%;
    color: whitesmoke;
}

#error-page h1 {
    margin-bottom: 20px;
    color: whitesmoke;
}

#error-page p {
    margin: 4px;
}